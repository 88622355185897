// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-es-js": () => import("./../../../src/pages/about.es.js" /* webpackChunkName: "component---src-pages-about-es-js" */),
  "component---src-pages-about-fr-js": () => import("./../../../src/pages/about.fr.js" /* webpackChunkName: "component---src-pages-about-fr-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-ptbr-js": () => import("./../../../src/pages/about.ptbr.js" /* webpackChunkName: "component---src-pages-about-ptbr-js" */),
  "component---src-pages-about-zh-js": () => import("./../../../src/pages/about.zh.js" /* webpackChunkName: "component---src-pages-about-zh-js" */),
  "component---src-pages-challenge-en-js": () => import("./../../../src/pages/challenge.en.js" /* webpackChunkName: "component---src-pages-challenge-en-js" */),
  "component---src-pages-challenge-es-js": () => import("./../../../src/pages/challenge.es.js" /* webpackChunkName: "component---src-pages-challenge-es-js" */),
  "component---src-pages-challenge-fr-js": () => import("./../../../src/pages/challenge.fr.js" /* webpackChunkName: "component---src-pages-challenge-fr-js" */),
  "component---src-pages-challenge-js": () => import("./../../../src/pages/challenge.js" /* webpackChunkName: "component---src-pages-challenge-js" */),
  "component---src-pages-challenge-ptbr-js": () => import("./../../../src/pages/challenge.ptbr.js" /* webpackChunkName: "component---src-pages-challenge-ptbr-js" */),
  "component---src-pages-challenge-zh-js": () => import("./../../../src/pages/challenge.zh.js" /* webpackChunkName: "component---src-pages-challenge-zh-js" */),
  "component---src-pages-companies-en-js": () => import("./../../../src/pages/companies.en.js" /* webpackChunkName: "component---src-pages-companies-en-js" */),
  "component---src-pages-companies-es-js": () => import("./../../../src/pages/companies.es.js" /* webpackChunkName: "component---src-pages-companies-es-js" */),
  "component---src-pages-companies-fr-js": () => import("./../../../src/pages/companies.fr.js" /* webpackChunkName: "component---src-pages-companies-fr-js" */),
  "component---src-pages-companies-js": () => import("./../../../src/pages/companies.js" /* webpackChunkName: "component---src-pages-companies-js" */),
  "component---src-pages-companies-ptbr-js": () => import("./../../../src/pages/companies.ptbr.js" /* webpackChunkName: "component---src-pages-companies-ptbr-js" */),
  "component---src-pages-companies-zh-js": () => import("./../../../src/pages/companies.zh.js" /* webpackChunkName: "component---src-pages-companies-zh-js" */),
  "component---src-pages-faq-en-js": () => import("./../../../src/pages/faq.en.js" /* webpackChunkName: "component---src-pages-faq-en-js" */),
  "component---src-pages-faq-es-js": () => import("./../../../src/pages/faq.es.js" /* webpackChunkName: "component---src-pages-faq-es-js" */),
  "component---src-pages-faq-fr-js": () => import("./../../../src/pages/faq.fr.js" /* webpackChunkName: "component---src-pages-faq-fr-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-faq-ptbr-js": () => import("./../../../src/pages/faq.ptbr.js" /* webpackChunkName: "component---src-pages-faq-ptbr-js" */),
  "component---src-pages-faq-zh-js": () => import("./../../../src/pages/faq.zh.js" /* webpackChunkName: "component---src-pages-faq-zh-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-fr-js": () => import("./../../../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-ptbr-js": () => import("./../../../src/pages/index.ptbr.js" /* webpackChunkName: "component---src-pages-index-ptbr-js" */),
  "component---src-pages-index-zh-js": () => import("./../../../src/pages/index.zh.js" /* webpackChunkName: "component---src-pages-index-zh-js" */),
  "component---src-pages-search-en-js": () => import("./../../../src/pages/search.en.js" /* webpackChunkName: "component---src-pages-search-en-js" */),
  "component---src-pages-search-es-js": () => import("./../../../src/pages/search.es.js" /* webpackChunkName: "component---src-pages-search-es-js" */),
  "component---src-pages-search-fr-js": () => import("./../../../src/pages/search.fr.js" /* webpackChunkName: "component---src-pages-search-fr-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-search-ptbr-js": () => import("./../../../src/pages/search.ptbr.js" /* webpackChunkName: "component---src-pages-search-ptbr-js" */),
  "component---src-pages-search-zh-js": () => import("./../../../src/pages/search.zh.js" /* webpackChunkName: "component---src-pages-search-zh-js" */),
  "component---src-templates-content-template-js": () => import("./../../../src/templates/ContentTemplate.js" /* webpackChunkName: "component---src-templates-content-template-js" */),
  "component---src-templates-part-intro-template-js": () => import("./../../../src/templates/PartIntroTemplate.js" /* webpackChunkName: "component---src-templates-part-intro-template-js" */)
}

